import orgService from '../../../../services/notisphere/organization'

export default {
    data() {
        return {
            item: {
                id: null,
                name: null,
                createdBy: null,
            },
            formType: 'INSERT'
        }
    },
    computed: {
        form(){
            if(this.formType == 'INSERT'){
                return {
                    title: 'Add New Supplier',
                    showAdminCreation: true,
                    showStatusSwitcher: false
                }
            }
            if(this.formType == 'UPDATE'){
                return {
                    title: 'Update Supplier',
                    showAdminCreation: false,
                    showStatusSwitcher: true
                }
            }
        },
        _status: {
            get() {
                return this.item.stt == 'VERIFIED' ? true : false
            },
            set(val) {
                if (val == true) {
                    this.item.stt = 'VERIFIED'
                }
                else {
                    this.item.stt = 'DEACTIVATED'
                }
            }
        }
    },
    methods: {
        forceRerenderInsert() {
            this.formType = 'INSERT'
            this.$refs.modal.show()
        },
        forceRerenderUpdate(id) {
            this.formType = 'UPDATE'
            this.getSupplier(id)
        },
        async save() {
            var isValid = await this.$refs['observer'].validate()
            if(isValid && this.formType == 'INSERT') {
                this.createSupplier()
            }
            if(isValid && this.formType == 'UPDATE'){
                this.updateSupplier()
            }
        },
        updateSupplier() {
            orgService.createSupplierName(this.item).then(resp=>{
                if (!resp.error) {
                    this.$emit('onCompleted', 'UPDATE')
                    this.$refs.modal.hide()
                }
            })
        },
        createSupplier() {
            orgService.createSupplierName(this.item).then(resp=>{
                if (!resp.error) {
                    this.$emit('onCompleted', 'INSERT')
                    this.$refs.modal.hide()
                }
            })
        },
        getSupplier(id) {
            this.item.id = id.id
            this.item.name = id.supplier_name
            this.$nextTick(()=>{
                this.$refs.modal.show()
            })
        },
        removeDomain(index) {
            this.item.domains.splice(index, 1)
        },
        addDomain() {
            this.item.domains.push('')
        }
    }
}