import orgServices from '../../../services/notisphere/organization'
import SupplierModal from './SupplierModal'
import SupplierListModal from './SupplierListModal'
import { SUPLLIERLIST_STATUS } from '../../../misc/commons'

export default {
    components: {
        SupplierModal,
        SupplierListModal
    },
    data() {
        return {
            total: 0,
            autocompleteItemsdata: [],
            items: [],
            searchQuery: {},
            status_id: null,
            status: [{name: 'Pending'},{name: 'Active'},{name: 'Deactivated'},{name: 'Linked'}],
            header: [
                {
                    label: 'Supplier Name',
                    name: 'supplier_name',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Date Added',
                    name: 'created_datetime',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Status',
                    name: 'status',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: '',
                    name: 'action',
                    row_text_alignment: 'text-right',
                    column_text_alignment: 'text-right'
                }
            ],

            org_status: SUPLLIERLIST_STATUS,

            supplierModalKey: 0,
            supplierListModalKey: 0,
        }
    },
    created()
    {
        this.GetSupplierNameList()
    },
    methods: {
        GetSupplierNameList() {

            orgServices.GetSupplierNameList().then((res) => {
                if(res.data.d.length > 0)
                    this.autocompleteItemsdata = []
                for (let i = 0; i < res.data.d.length; i++) {

                    this.autocompleteItemsdata.push(res.data.d[i])
                }
            })
        },
        onCompleted(type) {
            if (type == 'UPDATE') {
                this._showToast('Updated successfully!')
            }
            if (type == 'INSERT') {
                this._showToast('Added successfully!')
            }
            if (type == 'LINKED') {
                this._showToast('Supplier linking successful')
            }
            this.fetchData()
            this.GetSupplierNameList()
        },
        addMoreClick() {
            this.supplierModalKey += 1
            this.$nextTick(()=>{
                this.$refs.supplierModal.forceRerenderInsert()
            })
        },
        editSupplier(payload, e = null) {
            this.supplierModalKey += 1
            this.$nextTick(()=>{
                this.$refs.supplierModal.forceRerenderUpdate(payload)
            })
            if (e) {
                e.stopPropagation()
            }
        },

        linkSupplier(payload) {
            this.supplierListModalKey += 1
            var data=this.autocompleteItemsdata.filter(s=>s.id!=payload.id)
            var linkedSupplierName = this.autocompleteItemsdata.filter(s=>s.id==payload.linked_supplier_id)[0]?.text
            this.$nextTick(()=>{
                this.$refs.supplierListModal.forceRerenderSupplierList(payload,data,linkedSupplierName)
            })
        },
        updateSupplier(item, st,e) {
            orgServices.updateSupplierList(item,st).then(resp => {
                if(!resp.error){
                    this.fetchData()
                    this.GetSupplierNameList()
                    if(st == 'ACTIVE'){
                        this._showToast('Supplier is activated!')
                    }
                    else{
                        this._showToast('Supplier is deactivated!')

                    }
                }
            })

            e.stopPropagation()
        },
        fetchDelay() {
            this.$refs.table.fetchDelay()
        },
        fetchData() {

            orgServices.findSuppliersList({

                ...this.searchQuery,
                sortField: this.searchQuery.sort[0] ? this.searchQuery.sort[0].name : null,
                sortDirection: this.searchQuery.sort[0] ? this.searchQuery.sort[0].order : null
            },this.status_id).then(resp=>{
                if(!resp.error) {
                    this.items = resp.data.d.c
                    this.total = resp.data.d.t
                }
            })
        },
        deletePendingSupplier (item, e) {
            this._showConfirm('You are about to delete this organization, this cannot be undone.').then(confirm=>{
                if(confirm) {
                    orgServices.deletePendingSupplier(item.id).then(resp => {
                        if(!resp.error){
                            this.fetchData()
                            this._showToast('Deleted successfully!')
                        }
                    })
                }
            })
            e.stopPropagation()
        }
    }
}